import React from 'react'
import Intro from '../../../components/Intro';
import Section from '../../../components/Section';
import ImgSemifull from '../../../components/ImgSemifull';
import Back from '../../../components/Back';
import ImgSmall from '../../../components/ImgSmall';
import ImgFull from '../../../components/ImgFull';
import Pagination from '../../../components/Pagination';
import Title1 from '../../../components/Title1';
import Helmet from 'react-helmet';
import Logo from './logo-eurovision.svg';
import RowSvg from './row.svg';
import Components from './components.svg';
import Interface from './interface.svg';
import Selector from './selector.svg';
import styles from '../../../styles/styles.json';
import Layout from "../../../components/layoutProject"
import SEO from "../../../components/seo"

export const frontmatter = {
  id: 2,
  isWork: true,
  url: 'eurovision',
  title: 'Eurovision',
  subtitle: 'Identity Recognition Platform',
  path: '/work/eurovision',
  excerpt: `Identity Recognition Platform`,
};

const EurovisionPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Back />
      <div className="notch">
        <Helmet title={`${frontmatter.title} | ${data.site.siteMetadata.title}`} />
        <Intro full bg={styles.colors.eurovision.base} >
          <img className="brand" src={Logo} alt={frontmatter.title} />
          <ImgSmall img={RowSvg} />
          <p className='highlight'>Software that enables an organisation to centralise and manage video online.</p>
          <p>Video has grown over the past years, and companies demand better ways of managing their content reducing the complexity of existing systems.</p>
        </Intro>

        <Section full bg={styles.colors.eurovision.base}>
          <Title1 title='Layout' />
          <p>I worked on the visual design creating responsive layouts and a complete pattern library to be internally implemented.</p>
          <ImgSemifull img={Interface} alt='Nirovision symbol' style=" borderbright" />
          <p>The client asked us to create a template that different clients would customise, contextualising the experience.</p>
        </Section>

        <Section bg='#F9F9F9'>
          <Title1 title='Components library' />
          <p>We created some boundaries to limit the number of colours and made sure that decisions like contrast, typography, and brand placement didn’t affect the overall experience.</p>
          <ImgFull img={Components} alt='Timeline' />
        </Section>

        <Section dark full bg='#0056AC'>
          <Title1 title='Video management' />
          <p>In this version, we just covered the basic needs of adding, listing and editing content. Everything is modular and ready to use in their platform.</p>
          <ImgSemifull img={Selector} alt='Nirovision symbol' />
        </Section>

      </div>
      <Pagination currentproject={frontmatter.id} mydata={data} />
    </Layout>
  )
};

export default EurovisionPage;

export const query = graphql`
  query GatsbyEurovisionQuery {
    site {
      siteMetadata {
        title
      }
    }

    allJavascriptFrontmatter(filter: {frontmatter: {path: {regex: "/work/"}}}, sort: {fields: [frontmatter___id], order: ASC}) {
      edges {
        node {
          frontmatter {
            isWork
            path
            id
            title
            url
          }
        }
      }
    }
  
  }
`;