import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';

const Container = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: ${styles.spaces.xLarge};
  margin-bottom: ${styles.spaces.xLarge};
  padding-right: ${styles.spaces.xxLarge};
  padding-left: ${styles.spaces.xxLarge};
  & img {
    max-width: 1640px;
    margin: auto;
  }
  @media ${styles.breakpoints.tabletPortrait} {
    padding-right: ${styles.spaces.large};
    padding-left: ${styles.spaces.large};
  }
  @media ${styles.breakpoints.mobile} {
    padding-right: ${styles.spaces.small};
    padding-left: ${styles.spaces.small};
  }
`;

class ImgComponent extends React.Component {
  render() {
    const { img, alt, style } = this.props;
    return (
      <Container>
        <img className={'deviceScreen ' + style} src={img} alt={alt} />
      </Container>
    );
  }
}

export default ImgComponent
