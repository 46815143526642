import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';
import PropTypes from 'prop-types';

const Container = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: ${props => props.fit ? '0' : styles.spaces.xLarge};
  margin-bottom: ${props => props.fit ? '0' : styles.spaces.xLarge};
  // margin-top: ${styles.spaces.xLarge};
  // margin-bottom: ${styles.spaces.xLarge};
`;

class ImgComponent extends React.Component {
  render() {
    return (
      <Container  {...this.props}>
        <img className="deviceScreen" src={this.props.img} alt={this.props.alt} />
      </Container>
    );
  }
}


export default ImgComponent
