import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';

const Container = styled.div`
  max-width: 420px;
  margin: auto;
  margin-top: ${styles.spaces.xLarge};
  margin-bottom: ${styles.spaces.xLarge};
  & img {
    max-height: 90vh;
    max-width: 100%;
    width: auto;
    margin: auto;
  }
`;

class ImgComponent extends React.Component {
  render() {
    const { img, alt, style } = this.props;
    return (
      <Container>
        <img className={'imgSmall ' + style} src={img} alt={alt} />
      </Container>
    );
  }
}

export default ImgComponent
